<!--  -->
<template>
  <router-link
    :to="{ path: '/layout-h5/activity-detail-h5', query: { id: data.id } }"
    class="activity-item"
    target="_blank"
  >
    <div class="icon"></div>
    <div class="left">
      <div class="date">
        {{ data.year }} <span class="time">{{ data.date }}</span>
      </div>
      <h4 class="title">
        {{ data.title }}
      </h4>
      <p class="des">
        {{ data.intro }}
      </p>
      <img :src="data.image" alt="" class="cover" />
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    data: Object,
  },
  name: "activity-item",
  components: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {
    navToDetail() {
      this.$router.push({
        path: "/layout-h5/activity-detail-h5",
        query: {
          id: this.data.id,
        },
      });
    },
  },
  created() {},
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style lang="scss" scoped>
.activity-item {
  max-width: 1200px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  text-decoration: none;
  color: #333;
  .icon {
    margin: 2px 10px;
    width: 10px;
    height: 10px;
    background-color: #5b82d1;
    border-radius: 50%;
    border: 5px solid #fff;
    box-shadow: 0px 0px 2px #999;
    margin-right: 10px;
  }
  .left {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0 20px 0 0;
    .date {
      margin: 0 0 10px 0;
      font-size: 22px;
      // font-weight: bold;
      color: #5b82d1;
      .time {
        font-size: 15px;
        font-weight: normal;
      }
    }
    .title {
      margin: 0;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      line-height: 1.5;
      width: 100%;
    }
    .des {
      font-size: 14px;
      line-height: 1.5;
      margin: 10px 0;
      color: #999;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
    .cover {
      width: calc(100vw - 40px - 22px);
      height: 150px;
      border-radius: 10px;
    }
  }
}
</style>
