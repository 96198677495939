<!--  -->
<template>
  <div class="brand-activity">
    <ActivityItem
      v-for="item in list"
      :data="item"
      :key="item.id"
      class="item"
    ></ActivityItem>
    <div class="footer">
      <pagination
        background
        layout="prev, pager, next"
        :total="total"
        prev-text="上一页"
        next-text="下一页"
        @current-change="changeCurrent"
      >
      </pagination>
    </div>
  </div>
</template>

<script>
import ActivityItem from "../components/ActivityItemH5.vue";
import { Pagination } from "element-ui";
import { fetchActivityList } from "@/api/api.js";

export default {
  name: "brand-activity",
  components: {
    ActivityItem,
    Pagination,
  },
  data() {
    return {
      total: 1,
      page: 1,
      list: [],
    };
  },
  computed: {},
  watch: {},
  methods: {
    async getActivityList(page = 1) {
      try {
        let res = await fetchActivityList({
          data: {
            page: page,
          },
        });
        this.page = page;
        this.total = res.data.data.activity_num;
        this.list =
          page == 1
            ? res.data.data.activity_list
            : [...this.list, ...res.data.data.activity_list];
        console.log("res", res);
      } catch (err) {
        console.log("err", err);
      }
    },
    changeCurrent(page) {
      this.getActivityList(page);
    },
  },
  created() {
    this.getActivityList();
  },
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style lang="scss" scoped>
.brand-activity {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 50px 0;
  .item {
    margin-bottom: 40px;
  }
  .footer {
    padding: 40px 0;
  }
}
</style>
