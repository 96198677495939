<!--  -->
<template>
  <div class="activity-item" @click="navToDetail">
    <div class="icon"></div>
    <div class="left">
      <div class="date">
        {{ data.year }} <span class="time">{{ data.date }}</span>
      </div>
      <h4 class="title">
        {{ data.title }}
      </h4>
      <p class="des">
        {{ data.intro }}
      </p>
    </div>
    <img :src="data.image" alt="" class="cover" />
  </div>
</template>

<script>
import { tapOpen } from "@/utils/utils.js";

export default {
  props: {
    data: Object,
  },
  name: "activity-item",
  components: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {
    navToDetail() {
      let routeData = this.$router.resolve({
        path: "/layout/activity-detail",
        query: {
          id: this.data.id,
        },
      });
      // console.log('ddddd', routeData);
      tapOpen(routeData.href, "_blank");
      // this.$router.push({
      //   path: "/layout/activity-detail",
      //   query: {
      //     id: this.data.id,
      //   },
      // });
    },
  },
  created() {},
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style lang="scss" scoped>
.activity-item {
  max-width: 1200px;
  width: 1200px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  cursor: pointer;

  .icon {
    width: 20px;
    height: 20px;
    background-color: #5b82d1;
    border-radius: 50%;
    border: 5px solid #fff;
    box-shadow: 0px 0px 2px #999;
    margin-right: 10px;
  }
  .left {
    margin: 0 40px 0 0;
    flex: 1;
    .date {
      font-size: 30px;
      font-weight: bold;
      color: #5b82d1;
      .time {
        font-size: 16px;
        font-weight: normal;
      }
    }
    .title {
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }
    .des {
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      font-size: 14px;
      line-height: 1.6;
    }
  }
  .cover {
    flex: 1;
    // width: 40%;
    // object-fit: contain;
    object-fit: cover;
    border-radius: 10px;
    height: 218px;
  }
}
</style>
